import React from 'react';
import './Link.scss';
import { Link } from 'gatsby';
/**
 * @namespace Link
 * @function Link
 * @author Trevor Cash
 * @since 11/13/21
 * @version 1.0.0
 * @component
 */
export default function CustomLink({ children, to, external, className }) {
  if (external) {
    return (
      <a className='Link-Container' href={to} target='_blank' rel='noreferrer'>
        {children}
      </a>
    );
  } else {
    return (
      <Link className={'Link-Container ' + className} to={to}>
        {children}
      </Link>
    );
  }
}
