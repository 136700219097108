import React from 'react';
import './Sermons.scss';
import { Link } from 'gatsby';
import CustomLink from '../../Components/Link/Link';
import Layout from '../../Components/Layout/Layout';

import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';
import { RiLiveLine, RiBaseStationLine, RiHistoryLine } from 'react-icons/ri';

/**
 * @namespace Sermons
 * @function Sermons
 * @author Trevor Cash
 * @since 10/21/21
 * @version 1.0.0
 * @component
 */
export default function Sermons() {
  const data = useStaticQuery(graphql`
    query sermonsQuery {
      allSanityHome {
        nodes {
          currentSeries {
            slug {
              current
            }
            title
            _rawSummary
            banner {
              alt
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);
  const title = data?.allSanityHome?.nodes?.[0]?.currentSeries?.title;
  const banner = data?.allSanityHome?.nodes?.[0]?.currentSeries?.banner;
  const verbage = data?.allSanityHome?.nodes?.[0]?.currentSeries?._rawSummary;
  const currentSeries = data?.allSanityHome?.nodes?.[0]?.currentSeries;
  return (
    <Layout
      className='Sermon-Container'
      title='Sermons'
      description='Find more information on our series and also find out recent, current live stream, and past recordings of our sermons'
    >
      <h1>Sermons</h1>
      {(verbage || title) && (
        <section className='Series'>
          <h2>Current Series</h2>
          <img
            src={banner?.image?.asset?.url}
            alt={banner?.alt}
            loading='lazy'
          />
          <h3>{title}</h3>
          <PortableText value={verbage} />
          <CustomLink
            className='Series-Link'
            to={'/' + currentSeries?.slug?.current}
          >
            Read More
          </CustomLink>
        </section>
      )}
      <div className='InPerson'>
        <h2>In Person Service</h2>
        <CustomLink to='/worship'>View more information</CustomLink>
      </div>
      <h2>Checkout our sermons here online!</h2>
      <div className='Branch-Container'>
        <Link className='Livestream custom-btn btn' to='/sermons/live'>
          <div className='icon'>
            <RiBaseStationLine />
          </div>
          <div className='text'>
            <h3>Live Service</h3>
          </div>
        </Link>
        <Link className='Lateststream custom-btn btn' to='/sermons/recent'>
          <div className='icon'>
            <RiLiveLine />
          </div>
          <div className='text'>
            <h3>Latest Message</h3>
          </div>
        </Link>
        <a
          className='Paststream custom-btn btn'
          href='https://fbcwjohio.sermon.net/main'
        >
          <div className='icon'>
            <RiHistoryLine />
          </div>
          <div className='text'>
            <h3>Past Sermons</h3>
          </div>
        </a>
      </div>
    </Layout>
  );
}
